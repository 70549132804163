import React from 'react';
import useAppContext from 'lib/hooks/useAppContext';
import BrandedLandingPageLayout from 'routes/branded_landing_page/BrandedLandingPageLayout';
import CIOHomePage from 'routes/cio_homepage/CIOHomePage';

const CIOHomepage = () => {
  const { url } = useAppContext();
  return (
    <BrandedLandingPageLayout
      canonical={url}
      meta={{
        title:
          "Cook's Illustrated | Recipes That Work | We Test It All | Cook's Illustrated",
        description:
          'We are trusted by millions of home cooks as the authority for best recipes, how-to cooking tips, and step-by-step videos. Our magazine editors and test cooks explain exactly how and why our recipes work.',
      }}
    >
      <CIOHomePage />
    </BrandedLandingPageLayout>
  );
};

export default CIOHomepage;
