import { useEffect, useMemo, useRef } from 'react';
import styles from './cioHomePage.module.scss';
import cioHomePageData from './data/cioHomePageData';
import analytics from 'lib/analytics';

import useMarketingHat from 'lib/hooks/useMarketingHat';
import usePiano from 'components/integrations/PianoPlacements/hooks/usePiano';
import { linkSelectionHelper } from '../atk_homepage/linkSelectionHelper';

import { brandLandingAdsPresentedEvent } from 'components/integrations/PianoPlacements/eventHandlers/BrandsLanding/AdsPresented';
import { brandLandingFreeTrialEvent } from 'components/integrations/PianoPlacements/eventHandlers/BrandsLanding/FreeTrial';
import { brandLandingNewsletterEvent } from 'components/integrations/PianoPlacements/eventHandlers/BrandsLanding/EmailNewsletter';

import BrandHero from './../../components/brandpages/Hero/BrandHero';
import BrandImageGrid from './../../components/brandpages/ImageGrid/BrandImageGrid';
import FullWidthContentBlock from 'components/brandpages/FullWidthContentBlock/FullWidthContentBlock';
import FullWidthHeadline from 'components/brandpages/FullWidthHeadline/FullWidthHeadline';
import FullWidthImageCarousel from 'components/brandpages/FullWidthImageCarousel/FullWidthImageCarousel';
import FullWidthVideo from 'components/brandpages/FullWidthVideo/FullWidthVideo';
import StandardDocCardCarousel from 'components/brandpages/StandardDocCardCarousel/StandardDocCardCarousel';
import TeamMemberCarousel from 'components/brandpages/TeamMemberCarousel/TeamMemberCarousel';
import Testimonials from './../../components/brandpages/Testimonials/Testimonials';
import ToggleProcessGrid from 'components/brandpages/ToggleProcessGrid/ToggleProcessGrid';
import TwoColumnText from 'components/brandpages/TwoColumnText/TwoColumnText';
import TwoUpDocCardCarousel from 'components/brandpages/TwoUpDocCardCarousel/TwoUpDocCardCarousel';
import ContentJumpLinks from 'components/brandpages/ContentJumpLinks/ContentJumpLinks';

const CIOHomePage = () => {
  const {
    fullWidthContentBlocks: { fwb30Years, fwcbBakedInTheOven },
    fullWidthHeadlines: { fwhJoinUs, fwhWInnovativeContent },
    fullWidthCarousel: { fwcImages },
    fullWidthVideo: { fwvWhatsEattingDan, fwvTechniquely },
    twoColumnTexts: {
      tctGoodScience,
      tctOurSignature,
      tctWhatsEatingDan,
      tctMagazineCarousel,
      tctTechniquely,
    },
    teamMemberCarouselItems,
    brandImageGrids: { big30Years, bigBakedInTheOven },
    brandHeroes: { bhCulinaryProwess, bhTeach, bhJoinAndLearn },
    gameChangersCarousel,
    allTimeFavoritesCarousel,
    testimonials,
    twoUpCarousels: { tucTransformative, tucInnovative },
    ProcessGrid,
  } = useMemo(() => cioHomePageData, []);

  const { segment } = useMarketingHat();
  const shouldInit = useRef(false);
  const scienceRef = useRef<null | HTMLDivElement>(null);
  const magazineRef = useRef<null | HTMLDivElement>(null);
  const showsRef = useRef<null | HTMLDivElement>(null);

  const initPiano = usePiano({
    eventHandlers: [
      (e: any) =>
        brandLandingNewsletterEvent({
          event: e,
          location: 'brand_page_cio',
        }),
      (e: any) =>
        brandLandingFreeTrialEvent({
          event: e,
          location: 'brand_page_cio',
        }),
    ],
    postHandlers: !!shouldInit.current,
    segment,
  });

  useEffect(() => {
    if (shouldInit.current) return;
    brandLandingAdsPresentedEvent({
      location: 'brand_page_cio',
    });
    shouldInit.current = true;
  }, [initPiano]);

  useEffect(() => {
    analytics.track(
      'PAGEVIEW',
      { location: 'brand_page_cio' },
      { transport: 'sendBeacon' },
    );
  }, []);

  const jumpLinks = [
    {
      jumpLinkAnchor: 'science-anchor',
      linkText: 'Science',
      refElement: scienceRef,
    },
    {
      jumpLinkAnchor: 'magazine-anchor',
      linkText: 'Magazine',
      refElement: magazineRef,
    },
    {
      jumpLinkAnchor: 'shows-anchor',
      linkText: 'Shows',
      refElement: showsRef,
    },
  ];

  const trackPageClick = linkSelectionHelper();

  return (
    <div onClick={trackPageClick} className={styles.cioBrandPage}>
      <section aria-label="Sharpen your culinary prowess through science">
        <div className={styles.cioBrandHero}>
          <BrandHero
            altText={bhCulinaryProwess.altText}
            cloudinaryIds={bhCulinaryProwess.cloudinaryIds}
          ></BrandHero>
        </div>
      </section>

      <section
        aria-label="Jump to links"
        className={styles.setWidthContentBlock}
      >
        <div className={styles.cioContentLinks}>
          <ContentJumpLinks contentLinks={jumpLinks} />
        </div>
      </section>

      <section
        aria-label="30 Years of Revolutionizing Home Cooking"
        className={styles.setWidthContentBlock}
      >
        <div className={styles.homeCookingFWContentBlock}>
          <FullWidthContentBlock
            header={fwb30Years.header}
            content={fwb30Years.content}
          />
        </div>
        <div className={styles.homeCookingGrid}>
          <BrandImageGrid
            image1={big30Years.fullPortrait}
            image2={big30Years.fullLandscape}
            image3={big30Years.square1}
            image4={big30Years.square2}
          />
        </div>
      </section>

      <section
        ref={scienceRef}
        id="science-anchor"
        aria-label="Join us in the lab"
        className={styles.setWidthContentBlock}
      >
        <div className={styles.joinUsHeader}>
          <FullWidthHeadline header={fwhJoinUs.header} />
        </div>
        <div className={styles.joinUsContent}>
          <TwoColumnText {...tctGoodScience} />
        </div>
        <div className={styles.transformativeCarousel}>
          <TwoUpDocCardCarousel
            carouselTitle="Transformative Tips"
            carouselCards={tucTransformative}
          />
        </div>
        <div className={styles.innovativeCarousel}>
          <TwoUpDocCardCarousel
            carouselTitle="Groundbreaking Recipes"
            carouselCards={tucInnovative}
          />
        </div>
        <div className={styles.newsletterMarketingAd}>
          <div className="cio-newsletter" />
        </div>
        <div className={styles.ourSignatureContent}>
          <TwoColumnText {...tctOurSignature} />
        </div>
        <div className={styles.portraitsGrid}>
          <ToggleProcessGrid processGrid={ProcessGrid} />
        </div>
      </section>

      <section aria-label="Our favorite way to teach is showing">
        <div className={styles.historicRootsBrandHero}>
          <BrandHero
            altText={bhTeach.altText}
            cloudinaryIds={bhTeach.cloudinaryIds}
          ></BrandHero>
        </div>
      </section>

      <section
        aria-label="Our Innovative Content Is Everywhere You Are"
        className={styles.setWidthContentBlock}
      >
        <div className={styles.innovativeContentHeadline}>
          <FullWidthHeadline header={fwhWInnovativeContent.header} />
        </div>
        <div
          ref={magazineRef}
          className={styles.fullWidthImageCarouselWrapper}
          id="magazine-anchor"
        >
          <FullWidthImageCarousel images={fwcImages} />
        </div>
        <div className={styles.cooksIllustratedMagazineTextBlock}>
          <TwoColumnText {...tctMagazineCarousel} />
        </div>
        <div className={styles.gameChangersCarouselWrapper}>
          <StandardDocCardCarousel
            carouselCards={gameChangersCarousel}
            carouselTitle="Game-Changers"
          />
        </div>
        <div className={styles.favoritesCarouselWrapper}>
          <StandardDocCardCarousel
            carouselCards={allTimeFavoritesCarousel}
            carouselTitle="All-Time Favorites"
          />
        </div>
        <div className={styles.freeTrialMarketingAd}>
          <div className="cio-free-trial" />
        </div>
      </section>

      <section
        ref={showsRef}
        aria-label="What's Eating Dan"
        className={styles.setWidthContentBlock}
        id="shows-anchor"
      >
        <div className={styles.whatsEatingDanVideo}>
          <FullWidthVideo
            videoTitle="whats_eating_dan"
            source={fwvWhatsEattingDan.zypeIdSource}
          />
        </div>
        <div className={styles.whatsEatingDanContent}>
          <TwoColumnText {...tctWhatsEatingDan} />
        </div>
      </section>

      <section
        aria-label="Techniquely with Lan Lam"
        className={`${styles.setWidthContentBlock} ${styles.roseBorderTop}`}
      >
        <div className={styles.techniquelyVideo}>
          <FullWidthVideo
            videoTitle="techniquely"
            source={fwvTechniquely.zypeIdSource}
          />
        </div>
        <div className={styles.techniquelyContent}>
          <TwoColumnText {...tctTechniquely} />
        </div>
      </section>

      <section aria-label="Come join us and learn how to cook your best">
        <div className={styles.joinUsBrandHero}>
          <BrandHero
            altText={bhJoinAndLearn.altText}
            cloudinaryIds={bhJoinAndLearn.cloudinaryIds}
          ></BrandHero>
        </div>
      </section>

      <section
        className={`${styles.setWidthContentBlock} ${styles.trustOurRecipes}`}
      >
        <div className={styles.bakedFWContentBlock}>
          <FullWidthContentBlock
            header={fwcbBakedInTheOven.header}
            content={fwcbBakedInTheOven.content}
          />
        </div>
        <div className={styles.bakedImageGrid}>
          <BrandImageGrid
            image1={bigBakedInTheOven.fullPortrait}
            image2={bigBakedInTheOven.fullLandscape}
            image3={bigBakedInTheOven.square1}
            image4={bigBakedInTheOven.square2}
          />
        </div>
      </section>

      <section
        className={`${styles.setWidthContentBlock} ${styles.authorCarousel}`}
        aria-label="Meet the team"
      >
        <TeamMemberCarousel carouselCards={teamMemberCarouselItems} />
      </section>

      <section className={styles.testimonials} aria-label="Testimonials">
        <Testimonials quotes={testimonials} />
      </section>
    </div>
  );
};

export default CIOHomePage;
